// Licensed to the Apache Software Foundation (ASF) under one
// or more contributor license agreements.  See the NOTICE file
// distributed with this work for additional information
// regarding copyright ownership.  The ASF licenses this file
// to you under the Apache License, Version 2.0 (the
// "License"); you may not use this file except in compliance
// with the License.  You may obtain a copy of the License at
//
//   http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing,
// software distributed under the License is distributed on an
// "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
// KIND, either express or implied.  See the License for the
// specific language governing permissions and limitations
// under the License.
<template>
  <div class="form-layout" v-ctrl-enter="handleSubmit">
    <a-spin :spinning="loading">
      <a-form
        :ref="formRef"
        :model="form"
        :rules="rules"
        layout="vertical"
       >
        <a-form-item name="name" ref="name">
          <template #label>
            <tooltip-label :title="$t('label.name')" :tooltip="apiParams.name.description"/>
          </template>
          <a-input
            v-model:value="form.name"
            :placeholder="apiParams.name.description"
            v-focus="true"/>
        </a-form-item>
        <a-form-item name="displaytext" ref="displaytext">
          <template #label>
            <tooltip-label :title="$t('label.displaytext')" :tooltip="apiParams.displaytext.description"/>
          </template>
          <a-input
            v-model:value="form.displaytext"
            :placeholder="apiParams.displaytext.description"/>
        </a-form-item>
        <a-form-item name="zoneid" ref="zoneid">
          <template #label>
            <tooltip-label :title="$t('label.zoneid')" :tooltip="apiParams.zoneid.description"/>
          </template>
          <a-select
            :loading="loadingZone"
            v-model:value="form.zoneid"
            @change="val => changeZone(val)"
            showSearch
            optionFilterProp="label"
            :filterOption="(input, option) => {
              return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }" >
            <a-select-option v-for="zone in zones" :key="zone.id" :label="zone.name">
              <span>
                <resource-icon v-if="zone.icon" :image="zone.icon.base64image" size="1x" style="margin-right: 5px"/>
                <global-outlined v-else style="margin-right: 5px" />
                {{ zone.name }}
              </span>
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item name="cidr" ref="cidr">
          <template #label>
            <tooltip-label :title="$t('label.cidr')" :tooltip="apiParams.cidr.description"/>
          </template>
          <a-input
            v-model:value="form.cidr"
            :placeholder="apiParams.cidr.description"/>
        </a-form-item>
         <!-- WP Implementation - disable NetworkDomain -->
        <!-- <a-form-item name="networkdomain" ref="networkdomain">
          <template #label>
            <tooltip-label :title="$t('label.networkdomain')" :tooltip="apiParams.networkdomain.description"/>
          </template>
          <a-input
            v-model:value="form.networkdomain"
            :placeholder="apiParams.networkdomain.description"/>
        </a-form-item> -->
        <!-- /WP Implementation -->
        <a-form-item name="vpcofferingid" ref="vpcofferingid">
          <template #label>
            <tooltip-label :title="$t('label.vpcofferingid')" :tooltip="apiParams.vpcofferingid.description"/>
          </template>
          <a-select
            :loading="loadingOffering"
            v-model:value="form.vpcofferingid"
            showSearch
            optionFilterProp="label"
            :filterOption="(input, option) => {
              return option.children[0].children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }" >
            <a-select-option :value="offering.id" v-for="offering in vpcOfferings" :key="offering.id">
              {{ offering.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item name="start" ref="start">
          <template #label>
            <tooltip-label :title="$t('label.start')" :tooltip="apiParams.start.description"/>
          </template>
          <a-switch v-model:checked="form.start" />
        </a-form-item>
        <!-- WP Implementation SubAccount Selection if userrole === ResellerAdmin-->
        <a-form-item v-if="this.$store.getters.userInfo.rolename === 'ResellerAdmin'" ref="account" name ="account">
          <template #label>
            <tooltip-label :title="$t('label.create.vpc.for.account')" :tooltip="$t('label.create.vpc.for.account.tooltip')"/>
          </template>
          <a-select
            v-model:value="form.account"
            :placeholder="$t('label.select.account')">
            <a-select-option :value="accountName" :disabled="loggedInUserHasVPC">
              <span>
                {{ accountName }}
              </span>
            </a-select-option>
            <a-select-option v-for="account in subAccounts" :key="account.accountName" :disabled="hasVPC(account.uuid)">
              <span>
                {{ account.accountName }}
              </span>
            </a-select-option>
          </a-select>
        </a-form-item>
        <!-- WP Implementation -->
      </a-form>
      <div :span="24" class="action-button">
        <a-button @click="closeAction">{{ $t('label.cancel') }}</a-button>
        <a-button :loading="loading" ref="submit" type="primary" @click="handleSubmit">{{ $t('label.ok') }}</a-button>
      </div>
    </a-spin>
  </div>
</template>
<script>
import { ref, reactive, toRaw } from 'vue'
import { api } from '@/api'
import ResourceIcon from '@/components/view/ResourceIcon'
import TooltipLabel from '@/components/widgets/TooltipLabel'
// WP Implementation
import wpapi from '@/wpApi/myApi'
// /WP Implementation

export default {
  name: 'CreateVpc',
  components: {
    ResourceIcon,
    TooltipLabel
  },
  data () {
    return {
      // WP Implementation
      loggedInUserHasVPC: false,
      subAccounts: [],
      subAccountsWithVpc: [],
      accountUuid: this.$store.getters.userInfo.accountid,
      accountName: this.$store.getters.userInfo.account,
      domainUuid: this.$store.getters.userInfo.domainid,
      // /WP Implementation
      loading: false,
      loadingZone: false,
      loadingOffering: false,
      zones: [],
      vpcOfferings: [],
      selectedOffering: '',
      publicIpVpnInput: ''
    }
  },
  beforeCreate () {
    this.apiParams = this.$getApiParams('createVPC')
  },
  async created () {
    this.initForm()
    this.loggedAccountHasVpc()
    await this.fetchResellerCustomerAccounts()
    this.fetchZones()
  },
  methods: {
    // WP Implementation
    hasVPC (accountUUID) {
      return this.subAccountsWithVpc.includes(accountUUID)
    },
    validateVpcName (rule, value) {
      const errorMessage = this.$t('message.vpc.name.validation')
      if (!value.match(/^\d/) && rule.field === 'name') {
        return Promise.resolve()
      }
      return Promise.reject(errorMessage)
    },
    validateVpcCidr (rule, value) {
      const ipRegex = /^(\b(1?[0-9]{1,2}|2[0-4][0-9]|25[0-5])\b)\.(\b(1?[0-9]{1,2}|2[0-4][0-9]|25[0-5])\b)\.(\b(1?[0-9]{1,2}|2[0-4][0-9]|25[0-5])\b)\.(\b(1?[0-9]{1,2}|2[0-4][0-9]|25[0-5])\b)\/(1?[6-9]|[2][0-9]|3[0-2])$/
      const ipErrorMessage = this.$t('message.ip.validation')
      const cidrErrorMessage = this.$t('message.vpc.cidr.validation')

      if (!ipRegex.test(value) || rule.field !== 'cidr') {
        return Promise.reject(ipErrorMessage)
      }

      if (value === '10.1.2.0/23' || value === '10.1.0.0/22' || value === '10.1.0.0/21' || value === '10.1.0.0/20' ||
      value === '10.1.0.0/19' || value === '10.1.0.0/18' || value === '10.1.0.0/17' || value === '10.1.0.0/16') {
        return Promise.reject(cidrErrorMessage)
      }

      const [ip, prefix] = value.split('/')
      const decimalIp = this.toDecimal(ip)
      const maskRange = this.getMaskRange(decimalIp, prefix)
      const ipStartNum = maskRange.ipLow

      // 167838208 is the lowest Ip number of 10.1.2.0/24
      if (ipStartNum === 167838208) {
        return Promise.reject(cidrErrorMessage)
      }

      var thirdIpNum = ip.split('.')[2]

      for (let i = 0; i < thirdIpNum; i++) {
        var splitIp1 = ip.split('.')
        splitIp1[2] = i
        splitIp1[3] = 0
        const reconstructedIp = splitIp1.slice(0, 4).join('.')
        const reconstructedIpDecimal = this.toDecimal(reconstructedIp)
        const maskRangeCheck = this.getMaskRange(reconstructedIpDecimal, prefix)

        if (maskRangeCheck.ipLow === ipStartNum) {
          const validCidr = reconstructedIp + '/' + prefix
          const netErrorMessage = this.$t('message.vpc.net.validation') + validCidr
          if (validCidr === '10.1.2.0/23' || validCidr === '10.1.0.0/22' || validCidr === '10.1.0.0/21' || validCidr === '10.1.0.0/20' ||
          validCidr === '10.1.0.0/19' || validCidr === '10.1.0.0/18' || validCidr === '10.1.0.0/17' || validCidr === '10.1.0.0/16') {
            return Promise.reject(cidrErrorMessage)
          }
          return Promise.reject(netErrorMessage)
        }
      }
      var lastIpNum = ip.split('.')[3]

      for (let i = 0; i < lastIpNum; i++) {
        var splitIp2 = ip.split('.')
        splitIp2[3] = i
        const reconstructedIp = splitIp2.slice(0, 4).join('.')
        const reconstructedIpDecimal = this.toDecimal(reconstructedIp)
        const maskRangeCheck = this.getMaskRange(reconstructedIpDecimal, prefix)

        if (maskRangeCheck.ipLow === ipStartNum) {
          const netErrorMessage = this.$t('message.vpc.net.validation') + reconstructedIp + '/' + prefix
          return Promise.reject(netErrorMessage)
        }
      }
      return Promise.resolve()
    },
    toDecimal (ipString) {
      const d = ipString.split('.')
      return ((+d[0] * 256 + +d[1]) * 256 + +d[2]) * 256 + +d[3]
    },
    getMaskRange (ipNum, prefixSize) {
      const prefixMask = this.getPrefixMask(prefixSize)
      // const lowMask = this.getMask(32 - prefixSize)
      const ipLow = (ipNum & prefixMask) >>> 0
      // const ipHigh = (((ipNum & prefixMask) >>> 0) + lowMask) >>> 0
      return { ipLow /* ipHigh */ }
    },
    /* getMask (maskSize) {
      let mask = 0
      let i
      for (i = 0; i < maskSize; i += 1) {
        mask += (1 << i) >>> 0
      }
      return mask
    }, */
    getPrefixMask (prefixSize) {
      let mask = 0
      let i
      for (i = 0; i < prefixSize; i += 1) {
        mask += (1 << (32 - (i + 1))) >>> 0
      }
      return mask
    },
    async fetchResellerCustomerAccounts () {
      wpapi.getResellerSubAccounts(this.accountUuid).then(async response => {
        this.subAccounts = response.data.filter(x => x.state === 'enabled' && x.removed === null)
        // for each SubAccount: check if a vpc exists and push uuids of accounts with ecistsing vpc in subAccountsWithVpc list
        for (const subAccount of this.subAccounts) {
          await api('listVPCs', { account: subAccount.accountName, domainid: subAccount.domainUuid }).then(response => {
            const vpc = response.listvpcsresponse.vpc
            if (vpc !== undefined) {
              if (vpc[0].account === subAccount.accountName) {
                this.subAccountsWithVpc.push(subAccount.uuid)
              }
            }
          })
        }
      }).catch(error => {
        console.log(error)
      })
    },
    loggedAccountHasVpc () {
      api('listVPCs', { account: this.accountName, domainid: this.domainUuid }).then(response => {
        if (response.listvpcsresponse.vpc === undefined) {
          this.loggedInUserHasVPC = false
        } else { this.loggedInUserHasVPC = true }
      }).catch(error => {
        console.log(error)
        this.loggedInUserHasVPC = true
      })
    },
    // /WP Implementation
    initForm () {
      this.formRef = ref()
      this.form = reactive({
        start: true
      })
      this.rules = reactive({
        name: [
          { required: true, message: this.$t('message.error.required.input') },
          { validator: this.validateVpcName }
        ],
        displaytext: [{ required: true, message: this.$t('message.error.required.input') }],
        zoneid: [{ required: true, message: this.$t('label.required') }],
        cidr: [{ required: true, message: this.$t('message.error.required.input') }, { validator: this.validateVpcCidr }],
        vpcofferingid: [{ required: true, message: this.$t('label.required') }],
        account: [{ required: true, message: this.$t('label.required') }]
      })
    },
    fetchZones () {
      this.loadingZone = true
      api('listZones', { listAll: true, showicon: true }).then((response) => {
        const listZones = response.listzonesresponse.zone || []
        this.zones = listZones.filter(zone => !zone.securitygroupsenabled)
        this.form.zoneid = ''
        if (this.zones.length > 0) {
          this.form.zoneid = this.zones[0].id
          this.changeZone(this.form.zoneid)
        }
      }).finally(() => {
        this.loadingZone = false
      })
    },
    changeZone (value) {
      this.form.zoneid = value
      if (this.form.zoneid === '') {
        this.form.vpcofferingid = ''
        return
      }
      this.fetchOfferings()
    },
    fetchOfferings () {
      this.loadingOffering = true
      api('listVPCOfferings', { zoneid: this.form.zoneid, state: 'Enabled' }).then((reponse) => {
        this.vpcOfferings = reponse.listvpcofferingsresponse.vpcoffering
        this.form.vpcofferingid = this.vpcOfferings[0].id || ''
      }).finally(() => {
        this.loadingOffering = false
      })
    },
    closeAction () {
      this.$emit('close-action')
    },
    handleSubmit (e) {
      e.preventDefault()
      if (this.loading) return
      this.formRef.value.validate().then(() => {
        const values = toRaw(this.form)
        const params = {}
        for (const key in values) {
          const input = values[key]
          if (input === '' || input === null || input === undefined) {
            continue
          }
          params[key] = input
        }
        // if user === ResellerAdmin
        if (this.$store.getters.userInfo.rolename === 'ResellerAdmin') {
          if (this.accountName === values.account) {
            params.domainid = this.domainUuid
          } else {
            const domainUUID = this.subAccounts.filter(x => x.accountName === values.account)[0].domainUuid
            params.domainid = domainUUID
          }
        }
        this.loading = true
        const title = this.$t('label.add.vpc')
        const description = this.$t('message.success.add.vpc.network')
        api('createVPC', params).then(json => {
          const jobId = json.createvpcresponse.jobid
          if (jobId) {
            this.$pollJob({
              jobId,
              title,
              description,
              loadingMessage: `${title} ${this.$t('label.in.progress')}`,
              catchMessage: this.$t('error.fetching.async.job.result'),
              // WP Implementation
              // create NGF Data in DB for the sucessfully created vpc
              successMessage: this.$t('message.vpc.created'),
              successMethod: async result => {
                var params = new FormData()
                params.append('accountName', this.accountName)
                params.append('status', false)
                params.append('vpcUUID', result.jobresult.vpc.id)
                await wpapi.setNgfStatus(params).then(response => {
                }).catch(error => {
                  this.$notifyError(error)
                })
                await api('listPublicIpAddresses', {
                  vpcid: result.jobresult.vpc.id,
                  listAll: true
                }).then(json => {
                  const publicIps = json.listpublicipaddressesresponse.publicipaddress
                  for (var ip in publicIps) {
                    if (publicIps[ip].issourcenat) {
                      this.publicIpVpnInput = publicIps[ip].id
                    }
                  }
                }).catch(error => {
                  this.$notifyError(error)
                  console.log(error)
                })
                // call "createRemoteAccessVpn" APi after successfull creation of the vpc
                api('createRemoteAccessVpn', {
                  publicipid: this.publicIpVpnInput,
                  domainid: result.jobresult.vpc.domainid,
                  account: result.jobresult.vpc.account
                }).then(response => {
                  this.$pollJob({
                    jobId: response.createremoteaccessvpnresponse.jobid,
                    successMethod: result => {
                      const res = result.jobresult.remoteaccessvpn
                      this.$notification.success({
                        message: this.$t('label.status'),
                        description:
                          `${this.$t('message.enabled.vpn')} ${res.publicip}. ${this.$t('message.enabled.vpn.ip.sec')} ${res.presharedkey}`,
                        duration: 0
                      })
                    },
                    errorMessage: this.$t('message.enable.vpn.failed'),
                    loadingMessage: this.$t('message.enable.vpn.processing'),
                    catchMessage: this.$t('error.fetching.async.job.result')
                  })
                }).catch(error => {
                  this.$notifyError(error)
                })
              }
              // /WP Implementation
            })
          }
          this.closeAction()
        }).catch(error => {
          this.$notifyError(error)
        }).finally(() => {
          this.loading = false
        })
      }).catch(error => {
        this.formRef.value.scrollToField(error.errorFields[0].name)
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.form-layout {
  width: 80vw;
  @media (min-width: 700px) {
    width: 600px;
  }
}

.form {
  margin: 10px 0;
}
</style>
